@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts.css";
@import "slick.css";

/* dev helpers */
/* div {
  @apply border border-red-500;
} */

/* set up "full-page" layout */
body,
html,
#root {
  @apply w-full h-full overflow-hidden;
}

body {
  @apply fixed;
  @apply pb-safe-bottom;
}

#root {
  @apply relative;
}

/* reset focus states */
*,
*:focus,
*:hover {
  outline: 0px transparent !important;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
