@font-face {
  font-family: "Crispynut";
  src: url(./assets/fonts/Crispynut-Kids.otf) format("truetype");
}

@font-face {
  font-family: "Gotham";
  src: url(./assets/fonts/Gotham-Black.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: 100;
  font-style: normal;
  src: url(./assets/fonts/Metropolis/Metropolis-ExtraLight.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: 200;
  font-style: normal;
  src: url(./assets/fonts/Metropolis/Metropolis-Thin.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/Metropolis/Metropolis-Light.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/Metropolis/Metropolis-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/Metropolis/Metropolis-Medium.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/Metropolis/Metropolis-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/Metropolis/Metropolis-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: 800;
  font-style: normal;
  src: url(./assets/fonts/Metropolis/Metropolis-ExtraBold.otf) format("truetype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: 900;
  font-style: normal;
  src: url(./assets/fonts/Metropolis/Metropolis-Black.otf) format("truetype");
}
